import {useState, useEffect} from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import "./Header.css"
import OpenNewTab from '../../hooks/OpenNewTab';

const Header = () => {

    const {openInNewTab} = OpenNewTab();
  const [topNavClass, setTopNavClass] = useState('top-nav');
  const [navigationMenu, setNavigationMenu] = useState('navigation-menu');
  const location = useLocation();

  const topNav = (e) => {
    e.preventDefault();
    if (topNavClass === "top-nav") {
        setTopNavClass("top-nav responsive")
        setNavigationMenu("navigation-menu")
        } else {
            setTopNavClass("top-nav")
        }
    }

  const handleBaseNavToggler = (e) =>{
    e.preventDefault();
    if (navigationMenu === "navigation-menu") {
        setNavigationMenu("navigation-menu responsive")
        setTopNavClass("top-nav")
    } else {
        setNavigationMenu("navigation-menu")
    }
  }

  const Sticky = () => {
    if(window.scrollY > 50){
       document.getElementById('header-container').classList.add('fixed-top');
       document.getElementById('address').classList.add('address-none');
    }else{
      document.getElementById('header-container').classList.remove('fixed-top');
      document.getElementById('address').classList.remove('address-none');

    }
  }

  useEffect(() => {
    setNavigationMenu("navigation-menu")
    setTopNavClass("top-nav")
    window.addEventListener('scroll', Sticky)
  }, [location]);


  return (
    <header id='header-container'>
        {/* Top Bar Start */}
        <div  className="bg-white">
            <div className="row py-1">
                <div className='col-xl-7'>
                    <div className="d-flex align-items-center logo-site-info">
                        <div className="logo mx-2">
                            <NavLink to='/'>
                                <img src="/asset/img/logo/logo.jpg" alt="kyau-logo" className='cursor-pointer' width={55} />
                            </NavLink>
                        </div>
                        <div className='site-info'>
                            <h1 onClick={() => openInNewTab('http://www.kyalsc.edu.bd/')}>{process.env.REACT_APP_NAME}</h1>
                            <div className="d-flex justify-content-end">
                                 <sup className='fst-italic'>(English Version)</sup>
                            </div>
                            <h6 className='fst-italic'>FOUNDER: DR. M. M. AMJAD HUSSAIN</h6>
                        </div>
                    </div>
                </div>

                <div className='col-xl-5'>
    
                        <div className={topNavClass} id="topNav">
                            <ul className='list-unstyled m-0 d-flex justify-content-end align-items-end px-1'>
                                <li><NavLink to="/library">Library</NavLink></li>
                                <li><NavLink to="/building-directory">Building-Directory</NavLink></li>
                                <li><NavLink to="/career">Career</NavLink></li>
                                <li><NavLink to="/help-desk">Help-Desk</NavLink></li>
                                <li>
                                    <NavLink to="#" className="icon" onClick={topNav}> 
                                        {
                                            topNavClass === "top-nav" ? 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" className="bi bi-justify" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                                            </svg> 
                                            : 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" className="bi bi-x" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        }
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="address mt-1 pe-2" id='address'>
                            <label className='me-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                </svg> <small>+88 01753-235320</small>
                            </label>
                            <label>
                                    <NavLink to = "/contact" className="text-decoration-none text-dark" title='Click to see the Location of KYALSC'><small>EIIN: 134507, School Code: 4063, College Code: 4055</small></NavLink>
                            </label>
                        </div>
                </div>
            </div>
        </div>

        {/* Top Bar End */}
    
        <nav className={navigationMenu} id="navigationMenu">
        <NavLink to="/" className={location.pathname === "/" ? "active-menu" : ""}>HOME</NavLink>
        <div className="dropdown-container">
            <button className="dropbtn">ABOUT</button>
            <div className="dropdown-content">
                <NavLink to="/about/history">History</NavLink>
                <NavLink to="/authority/founder">Founder</NavLink>
                {/* <NavLink to="/about/background-kyalsc">Background of KYALSC</NavLink> */}
                <NavLink to="/authority/governing-body">Governing Body</NavLink>
                <NavLink to="/about/mission-vision">Mission & Vision</NavLink>
                <div className="sub-dropdown-container">
                    <button className="dropbtn">Messages
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </button>
                    <div className='sub-dropdown-content'>
                        <NavLink to="/authority/messages/chairman">Chairman</NavLink>
                        <NavLink to="/authority/messages/vice-chairman">Vice Chairman</NavLink>
                        <NavLink to="/authority/messages/principal">Principal</NavLink>
                        <NavLink to="/authority/messages/head-teacher">Head Teacher</NavLink>
                    </div>
                </div>
           

            </div>
        </div>

        <div className="dropdown-container">
            <button className="dropbtn">ADMISSION</button>
            <div className="dropdown-content">
                <NavLink to="/admission/prospectus">Prospectus</NavLink>
                <NavLink to="/admission/higher-secondary">Higher Secondary (College)</NavLink>
                <NavLink to="/admission/secondary">Secondary (School)</NavLink>
                <NavLink to="/admission/primary">Primary</NavLink>
                <NavLink to="/admission/pre-primary">Pre-Primary</NavLink>
                <NavLink to="/admission/admission-form">Admission Form</NavLink>
                <NavLink to="/admission/transfer-method">Transfer Method</NavLink>
        
            </div>
        </div>

        <div className="dropdown-container">
            <button className="dropbtn">ACADEMIC</button>
            <div className="mega-dropdown-content">
                <div className="header">
                    <h4>Academic Information</h4>
                </div>
                <div className="mega-row">
                    <div className="mega-column">
                        <h5>Academic Rules & Policy</h5>
                        <NavLink to="academic/policy" target="_parent">Academic Policy</NavLink> 
                        <NavLink to="academic/rules-regulation" target="_parent">Rules & Regulation</NavLink>   
                    </div>
                    <div className="mega-column">
                        <h5>Academic Section</h5>
                        <NavLink to="academic/higher-secondary" target='_parent'>Higher Secondary</NavLink> 
                        <NavLink to="academic/secondary" target='_parent'>Secondary</NavLink> 
                        <NavLink to="academic/primary" target='_parent'>Primary</NavLink> 
                        <NavLink to="academic/pre-primary" target='_parent'>Pre-Primary</NavLink> 
                    </div>
                    <div className="mega-column">
                        <h5>Academic Contents</h5>
                        <NavLink to="academic/calendar" target='_parent'>Academic Calendar</NavLink> 
                        <NavLink to="academic/holiday-list" target='_parent'>Holiday List</NavLink> 
                        <NavLink to="academic/magazine" target='_parent'>Magazine</NavLink> 
                      
                    </div>
                    <div className="mega-column">
                        <h5>Text Books & Syllabus</h5>
                        <hr/>
                        <NavLink to="academic/class-routine" target='_parent'>Class Routine </NavLink>
                        <NavLink to="academic/text-books" target='_parent'>Text Books </NavLink>
                        <NavLink to="academic/syllabus" target='_parent'>Syllabus </NavLink>
                        {/* <NavLink to="#">Lesson Plan </NavLink>
                        <NavLink to="#">Digital Contents </NavLink> */}
                    </div>
                </div>
            </div>
        </div>
      
        <div className="dropdown-container">
            <button className="dropbtn">CO-CURRICULAR ACTIVITIES</button>
            <div className="dropdown-content">
                {/* <NavLink to="/co-curricular-activities/house-activities">House Activities</NavLink>
                <NavLink to="/co-curricular-activities/weekly-activities">Weekly Activities</NavLink> */}
                <NavLink to="/co-curricular-activities/national-days">National Days</NavLink>
                <div className="sub-dropdown-container">
                    <button className="dropbtn">Club
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </button>
                    <div className='sub-dropdown-content'>
                        <NavLink to="/co-curricular-activities/club/taekwondo">Taekwondo</NavLink>
                        <NavLink to="/co-curricular-activities/club/art-craft">Art & Craft</NavLink>
                        <NavLink to="/co-curricular-activities/club/scout">Scout</NavLink>
                        <NavLink to="/co-curricular-activities/club/language">Language</NavLink>
                        <NavLink to="/co-curricular-activities/club/cultural">Cultural</NavLink>
                        <NavLink to="/co-curricular-activities/club/sports">Sports</NavLink>
                        <NavLink to="/co-curricular-activities/club/debating">Debating</NavLink>
                    </div>
                </div>
                <NavLink to="/co-curricular-activities/achievement">Achievement</NavLink>
                <NavLink to="/co-curricular-activities/corner">Corners</NavLink>
                {/* <div className="sub-dropdown-container">
                    <button className="dropbtn">Corners
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </button>
                    <div className='sub-dropdown-content'>
                        <NavLink to="/students/student-life/class-room">Freedom Fighters</NavLink>
                        <NavLink to="/students/student-life/campus">Bangabandhu</NavLink>
                        <NavLink to="/students/student-life/facility">Founder Chairman</NavLink>
                    </div>
                </div> */}
            </div>
        </div>

        <div className="dropdown-container">
            <button className="dropbtn">OFFICES</button>
            <div className="dropdown-content">
                <NavLink to="/offices/principal">Principal</NavLink>
                <NavLink to="/offices/head-teacher">Head Teacher</NavLink>
                <NavLink to="/offices/controller-of-examination">Controller of Examination</NavLink>
                <NavLink to="/offices/account">Accounts</NavLink>
                <NavLink to="/offices/admission">Admission</NavLink>
                <NavLink to="/offices/library">Library</NavLink>
                <NavLink to="/offices/Taekwondo">Taekwondo</NavLink>
                <NavLink to="/offices/sport-culture">Sports & Culture</NavLink>
                <NavLink to="/offices/lab">Lab</NavLink>
            </div>
        </div>



        <div className="dropdown-container">
            <button className="dropbtn">PEOPLE</button>
            <div className="dropdown-content">
                <NavLink to="people/higher-secondary">Higher Secondary (College)</NavLink>
                <NavLink to="people/secondary">Secondary (School)</NavLink>
                <NavLink to="people/primary">Primary</NavLink>
                <NavLink to="people/pre-primary">Pre-primary</NavLink>
            </div>
        </div>

        <div className="dropdown-container">
            <button className="dropbtn">STUDENTS</button>
            <div className="dropdown-content">
                <NavLink to="/students/information">Students Information</NavLink>
                <div className="sub-dropdown-container">
                    <button className="dropbtn">Student Life
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </button>
                    <div className='sub-dropdown-content'>
                        <NavLink to="/students/student-life/campus">Campus</NavLink>
                        <NavLink to="/students/student-life/facility">Facility</NavLink>
                        <NavLink to="/students/student-life/class-room">Class Room</NavLink>
                        <NavLink to="/students/student-life/lab">Lab</NavLink>
                    </div>
                </div>


                <div className="sub-dropdown-container">
                    <button className="dropbtn">Dress Code
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </button>
                    <div className='sub-dropdown-content'>
                        <NavLink to="/students/dress-code/college">College Section</NavLink>
                        <NavLink to="/students/dress-code/school">School Section</NavLink>
                        <NavLink to="/students/dress-code/primary">Primary Section</NavLink>
                        <NavLink to="/students/dress-code/pre-primary">Pre-Primary Section</NavLink>
                    </div>
                </div>
                <NavLink to="/students/future">Future Student</NavLink>
                <NavLink to="/students/current">Current Student</NavLink>
            </div>
        </div>

        <div className="dropdown-container">
            <button className="dropbtn">RESULT</button>
            <div className="dropdown-content">
                <NavLink to="result/academic">Academic</NavLink>
                <NavLink to="result/public">Public</NavLink>
            </div>
        </div>

        <div className="dropdown-container">
            <button className="dropbtn">GALLERY</button>
            <div className="dropdown-content">
                <NavLink to="/photo-gallery">Photo Gallery</NavLink>
                <NavLink to="/tour-gallery">Tour Gallery</NavLink>
                <NavLink to="/video-gallery">Video Gallery</NavLink>
            </div>
        </div>


        <NavLink to="contact" className={location.pathname === "/contact" ? "active-menu" : ""}>CONTACT</NavLink>
        <NavLink to="#" onClick={handleBaseNavToggler} id='baseNavToggler'>
            {
                
                navigationMenu === "navigation-menu" ? 
                (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-layout-text-sidebar-reverse" viewBox="0 0 16 16">
                    <path d="M12.5 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm0 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm.5 3.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm-.5 2.5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5z"/>
                    <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2zM4 1v14H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h2zm1 0h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5V1z"/>
               </svg>)  : 
                (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>)
            }

        </NavLink>
        </nav>

    </header>
  )
}

export default Header